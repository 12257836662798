.bg-fb{
    background: #1C3AA9;
    border: 1px solid #1C3AA9;

}

.g-recaptcha{
    display: inline-block;
}

.avatar{
    height: 25px;
    width: 25px;
    border-radius: 25px;
    object-fit: contain;
    background-color: #eee;
    padding: 5px;
}

.click{
    cursor: pointer;
    height: 35px;
    text-transform: capitalize!important;
}

.border-bottom-green {
    border-bottom: 1px solid #066466 !important;
    font-weight: 700;
  }