.border-bottom-green{
    border-bottom: 1px solid #066466 !important;
}
.avatarprofle{
    height: 50px;

}

.avatarprofle1{
    height: 70px;


}

@media screen and (max-width: 798px) {
    .avatarprofle{
        height: 25px !important;
    
    }
  }